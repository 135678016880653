<template>
  <el-dialog class="dialog-standard standard-plan-edit" :close-on-click-modal="false" :close-on-press-escape="false"
    :show-close="false" :fullscreen="isToFullScreen" width="1200px" top="100px" visible>
    <section class="dialog-header" slot="title">
      <span class="dialog-title">
        直客标准融资方案组费率配置
        <el-tag :type="tag == 1 ? '' : 'info'" size="medium" @click="tag = 1">单笔</el-tag>
        <el-tag :type="tag == 2 ? '' : 'info'" size="medium" @click="tag = 2">年化</el-tag>
      </span>
      <span class="right-icon">
        <i :class="[isToFullScreen ? 'el-icon-news' : 'el-icon-full-screen']" @click="setFullScreen"></i>
        <i class="el-icon-close" @click="handleCancel"></i>
      </span>
    </section>
    <section class="dialog-body">
      <div class="table-list">
        <p class="tips">注：对客利率上一档价格小于等于下一档</p>
        <avue-crud v-if="tag == 1" ref="crud" :table-loading="isLoading" :option="option" :data="dataList.day">
          <template #fundRate="{ row }">
            <el-input-number v-model="row.fundRate" :precision="4" :step="0.1" :min="0"></el-input-number>
          </template>
        </avue-crud>
        <avue-crud v-if="tag == 2" ref="crud" :table-loading="isLoading" :option="optionYear" :data="dataList.year">
          <template #fundRate="{ row }">
            <el-input-number v-model="row.fundRate" :precision="4" :step="0.1" :min="0"></el-input-number>
          </template>
        </avue-crud>
      </div>
    </section>
    <section class="dialog-footer">
      <el-button type="primary" icon="el-icon-circle-check" v-loading="isSubmiting" @click="handleConfirm">
        保存
      </el-button>
      <el-button class="cancel" icon="el-icon-circle-close" @click="handleCancel">取消</el-button>
    </section>
  </el-dialog>
</template>

<script>
// done 费用管理-融资方案-直客方案管理 -->添加单笔/年化配置
import { doGet, doPost } from '@/router/axios'
import { dealNull } from '@/filters/index'

export default {
  name: 'StandardPlanEdit',
  props: {},
  data() {
    return {
      // tag绑定
      tag: '1',
      isToFullScreen: false,
      isLoading: true,
      isSubmiting: false, //防重复提交
      //对客利率（单笔%） 列表配置
      dDayList: [
        {
          turnoverStartDay: '1',
          turnoverEndDay: '4',
          maxDay: '7',
        },
        {
          turnoverStartDay: '5',
          turnoverEndDay: '6',
          maxDay: '10',
        },
        {
          turnoverStartDay: '7',
          turnoverEndDay: '10',
          maxDay: '20',
        },
        {
          turnoverStartDay: '11',
          turnoverEndDay: '15',
          maxDay: '25',
        },
        {
          turnoverStartDay: '16',
          turnoverEndDay: '20',
          maxDay: '30',
        },
        {
          turnoverStartDay: '21',
          turnoverEndDay: '26',
          maxDay: '35',
        },
        {
          turnoverStartDay: '27',
          turnoverEndDay: '30',
          maxDay: '40',
        },
        {
          turnoverStartDay: '31',
          turnoverEndDay: '35',
          maxDay: '45',
        },
        {
          turnoverStartDay: '36',
          turnoverEndDay: '40',
          maxDay: '50',
        },
        {
          turnoverStartDay: '41',
          turnoverEndDay: '45',
          maxDay: '55',
        },
      ],
      // 对客利率（单笔%）
      option: {
        addBtn: false,
        refreshBtn: false,
        columnBtn: false,
        header: false,
        menu: false,
        border: false,
        stripe: true,
        align: 'left',
        column: [
          {
            label: '对客利率（单笔%）',
            prop: 'fundRate',
            width: 300,
          },
          {
            label: '平均回款天数',
            prop: 'avePayDays',
            width: 300,
          },
          {
            label: '最大用款天数',
            prop: 'maxDay',
          },
        ],
      },
      //对客利率（年化%） 列表配置
      dYearList: [
        {
          turnoverStartDay: '1',
          turnoverEndDay: '4',
          maxDay: '7',
        },
        {
          turnoverStartDay: '5',
          turnoverEndDay: '6',
          maxDay: '10',
        },
        {
          turnoverStartDay: '7',
          turnoverEndDay: '10',
          maxDay: '20',
        },
        {
          turnoverStartDay: '11',
          turnoverEndDay: '15',
          maxDay: '25',
        },
        {
          turnoverStartDay: '16',
          turnoverEndDay: '20',
          maxDay: '30',
        },
        {
          turnoverStartDay: '21',
          turnoverEndDay: '26',
          maxDay: '35',
        },
        {
          turnoverStartDay: '27',
          turnoverEndDay: '30',
          maxDay: '40',
        },
        {
          turnoverStartDay: '31',
          turnoverEndDay: '35',
          maxDay: '45',
        },
        {
          turnoverStartDay: '36',
          turnoverEndDay: '40',
          maxDay: '50',
        },
        {
          turnoverStartDay: '41',
          turnoverEndDay: '45',
          maxDay: '55',
        },
      ],
      // 对客利率（年化%）
      optionYear: {
        addBtn: false,
        refreshBtn: false,
        columnBtn: false,
        header: false,
        menu: false,
        border: false,
        stripe: true,
        align: 'left',
        column: [
          {
            label: '对客利率（年化%）',
            prop: 'fundRate',
            width: 300,
          },
          {
            label: '平均回款天数',
            prop: 'avePayDays',
            width: 300,
          },
          {
            label: '最大用款天数',
            prop: 'maxDay',
          },
        ],
      },
      dataList: {
        day: [],
        year: [],
      },
    }
  },
  watch: {},
  created() { },
  mounted() {
    this.isLoading = true
    this.getTemplateRates()
  },
  methods: {
    /** 获取初始模版渠道数据 */
    getTemplateRates() {
      const api = 'bium-portal/controller/fin-ops/ratePlan/queryDirectList' //一级渠道模版ID：1
      doPost(api).then(({ data }) => {
        if (data) {
          let listDay = []
          let listYear = []
          const list = data.result
          for (let index = 0; index < list.length; index++) {
            list[index].billingMethod == 'Charge_Per_transaction'
              ? listDay.push(list[index])
              : listYear.push(list[index])
          }
          this.dataList = {
            day: this.dealResData(listDay),
            year: this.dealResData(listYear),
          }
        }
        this.isLoading = false
      })
    },
    /** 保存提交数据 */
    saveRateData(templateList1, templateList2) {
      this.isSubmiting = true
      const api = 'bium-portal/controller/fin-ops/ratePlan/saveDirectRate'
      let perRateDetailList = []
      let annRateDetailList = []
      templateList1.forEach((item) => {
        perRateDetailList.push({
          order: item.order,
          channelRate: Number((item.fundRate / 100).toFixed(6)),
        })
      })
      templateList2.forEach((item) => {
        annRateDetailList.push({
          order: item.order,
          channelRate: Number((item.fundRate / 100).toFixed(6)),
        })
      })
      this.doPost(api, { perRateDetailList: perRateDetailList, annRateDetailList: annRateDetailList })
        .then(({ data }) => {
          if (data) {
            this.$notify.success('标准融资方案组修改成功。')
            this.$options.confirm && this.$options.confirm()
          } else {
            this.isSubmiting = false
          }
        })
        .catch(() => {
          this.isSubmiting = false
        })
    },

    /** 确认 */
    handleConfirm() {
      if (!this.isSubmiting) {
        let dataList1 = this.dataList.day
        let dataList2 = this.dataList.year
        // 先校验是否全部填写完
        const noValue = dataList1.find((item) => !item.fundRate)
        if (noValue) {
          this.$notify.error('还有未填写完的信息，请检查后再提交。')
          return
        }
        // 再校验“对客利率下一档价格大于等于上一档的”规则
        const maxIdx = dataList1.length - 1 //最大下标索引
        const compareValid = dataList1.every((item, index, arr) => {
          if (index < maxIdx) {
            const nextFundRate = arr[index + 1].fundRate //下一档的对客利率
            return nextFundRate >= item.fundRate
          }
          return true
        })

        // 先校验是否全部填写完
        const noValue1 = dataList2.find((item) => !item.fundRate)
        if (noValue1) {
          this.$notify.error('还有未填写完的信息，请检查后再提交。')
          return
        }
        // 再校验“对客利率下一档价格大于等于上一档的”规则
        const maxIdx1 = dataList2.length - 1 //最大下标索引
        const compareValid1 = dataList2.every((item, index, arr) => {
          if (index < maxIdx1) {
            const nextFundRate = arr[index + 1].fundRate //下一档的对客利率
            return nextFundRate >= item.fundRate
          }
          return true
        })
        // 校验通过
        if (compareValid && compareValid1) {
          this.saveRateData(dataList1, dataList2)
        } else {
          this.$notify.error('请检查数据是否满足“对客利率上一档价格小于等于下一档”的规则。')
        }
      }
    },
    /** 关闭 */
    handleCancel() {
      this.$options.cancel && this.$options.cancel()
    },
    /** 全屏设置 */
    setFullScreen() {
      this.isToFullScreen = !this.isToFullScreen
    },

    /** 预处理接口返回数据 */
    dealResData(resData) {
      if (resData && resData.length) {
        return resData.map((item, idx) => {
          const { channelRate } = item
          return {
            ...item,
            avePayDays: `${dealNull(this.dDayList[idx].turnoverStartDay)}~${dealNull(
              this.dDayList[idx].turnoverEndDay,
            )}天`,
            maxDay: this.dDayList[idx].maxDay,
            fundRate: Number(dealNull((Number(channelRate) * 100).toFixed(4), 0)),
          }
        })
      }
      return []
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-title {
  display: flex;
  align-items: center;

  .el-tag {
    margin-left: 15px;
    cursor: pointer;
  }
}

.standard-plan-edit {
  .table-list {
    .tips {
      margin-bottom: 10px;
      padding: 2px 10px;
      background-color: #ecf5ff;
      border: 1px solid #d9ecff;
      border-radius: 4px;
      color: #e6a23c;
    }

    /deep/.el-input-number .el-input input {
      text-align: center;
    }
  }

  /deep/.el-form .el-input-group--prepend .el-input__inner {
    width: 80px;
    text-align: center;
  }
}
</style>
